.privacy-commitment-wrapper {
  min-height: 100vh;
  background: linear-gradient(180deg, #8338EC 0%, rgba(58, 134, 255, 0.76) 100%);
  position: relative;
}

.privacy-commitment-heading, .privacy-commitment-footer {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.commitment-body {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

.commitment-wrapper {
  display: grid;
}

.privacy-commitment-footer-wrapper {
  text-align: center;
}


@media (min-width: 1025px) {
  /* desktop styles */
  .privacy-commitment-heading {
    font-size: 2vw;
    padding: 10px;
    margin: 0 12vw;
  }

  .privacy-commitments {
    padding: 30px 0;
    margin: 0 12vw;
  }
  
  .commitment-wrapper {
    margin: 20px;
    text-align: left;
    grid-template-columns: 3% 97%;
  }

  .commitment-body {
    font-size: 1.5vw;
    align-self: center
  }
  
  .commitment-bullet {
    height: auto;
    width: 30px;
    align-self: center;
  }

  .privacy-commitment-lock {
    top: 6%;
    left: 64%;
    position: absolute;
    height: auto;
    width: 20vw
  }

  .privacy-commitment-footer {
    font-size: 2vw;
    padding: 200px 0;
    margin: 0 30vw;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .privacy-commitment-heading {
    font-size: 4vw;
    padding: 10px;
    margin: 0 12vw;
  }

  .privacy-commitments {
    padding: 30px 0;
    margin: 0 12vw;
  }
  
  .commitment-wrapper {
    margin: 20px;
    text-align: left;
    grid-template-columns: 7% 93%;
  }

  .commitment-body {
    font-size: 2vw;
    align-self: center;
  }
  
  .commitment-bullet {
    height: auto;
    width: 30px;
    align-self: center;
  }

  .privacy-commitment-lock {
    top: 14%;
    left: 64%;
    position: absolute;
    height: auto;
    width: 20vw
  }

  .privacy-commitment-footer {
    font-size: 4vw;
    padding: 100px 0;
    margin: 0 5vw;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .privacy-commitment-heading {
    font-size: 4vw;
    padding: 10px;
    margin: 0 12vw;
  }

  .privacy-commitments {
    padding: 30px 0;
    margin: 0 12vw;
  }
  
  .commitment-wrapper {
    margin: 20px;
    text-align: left;
    grid-template-columns: 7% 93%;
  }

  .commitment-body {
    font-size: 2vw;
    align-self: center;
  }
  
  .commitment-bullet {
    height: auto;
    width: 30px;
    align-self: center;
  }

  .privacy-commitment-lock {
    top: 14%;
    left: 64%;
    position: absolute;
    height: auto;
    width: 20vw
  }

  .privacy-commitment-footer {
    font-size: 4vw;
    padding: 100px 0;
    margin: 0 5vw;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .privacy-commitment-heading {
    font-size: 4vw;
    line-height: 22px;
    padding: 10px;
    margin: 0 12vw;
  }

  .privacy-commitments {
    padding: 30px 0;
    margin: 0 12vw;
  }
  
  .commitment-wrapper {
    margin: 20px;
    text-align: left;
    grid-template-columns: 20% 80%;
  }

  .commitment-body {
    font-size: 3vw;
    align-self: center;
  }
  
  .commitment-bullet {
    height: auto;
    width: 30px;
    align-self: center;
  }

  .privacy-commitment-lock {
    top: 14%;
    left: 64%;
    position: absolute;
    height: auto;
    width: 20vw;
    display: none;
  }

  .privacy-commitment-footer {
    font-size: 4vw;
    padding: 30px 0;
    margin: 0 5vw;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .privacy-commitment-heading {
    font-size: 4vw;
    line-height: 22px;
    padding: 10px;
    margin: 0 12vw;
  }

  .privacy-commitments {
    padding: 30px 0;
    margin: 0 12vw;
  }
  
  .commitment-wrapper {
    margin: 20px;
    text-align: left;
    grid-template-columns: 20% 80%;
  }

  .commitment-body {
    font-size: 3vw;
    align-self: center;
  }
  
  .commitment-bullet {
    height: auto;
    width: 30px;
    align-self: center;
  }

  .privacy-commitment-lock {
    top: 14%;
    left: 64%;
    position: absolute;
    height: auto;
    width: 20vw;
    display: none;
  }

  .privacy-commitment-footer {
    font-size: 4vw;
    padding: 30px 0;
    margin: 0 5vw;
  }
}