.user-phone-numbers-wrapper {
  background-color: #8338EC;
  height: 100%;
  min-height: 100vh;
}

.user-phone-numbers-heading-wrapper, .user-phone-numbers-buy-wrapper {
  text-align: center;
}

.user-phone-numbers-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.user-phone-numbers-list-wrapper > div {
  display: block;
  margin: 0 auto;
}


@media (min-width: 1025px) {
  /* desktop styles */
  .user-phone-numbers-heading-wrapper {
    padding: 50px 0;
  }

  .user-phone-numbers-heading {
    font-size: 2vw;
  }

  .user-phone-numbers-buy-wrapper {
    padding: 50px 0;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .user-phone-numbers-heading-wrapper {
    padding: 50px 0;
  }

  .user-phone-numbers-heading {
    font-size: 4vw;
  }

  .user-phone-numbers-buy-wrapper {
    padding: 50px 0;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .user-phone-numbers-heading-wrapper {
    padding: 50px 0;
  }

  .user-phone-numbers-heading {
    font-size: 4vw;
  }

  .user-phone-numbers-buy-wrapper {
    padding: 50px 0;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .user-phone-numbers-heading-wrapper {
    padding: 30px 0;
  }

  .user-phone-numbers-heading {
    font-size: 7vw;
  }

  .user-phone-numbers-buy-wrapper {
    padding: 30px 0;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .user-phone-numbers-heading-wrapper {
    padding: 30px 0;
  }

  .user-phone-numbers-heading {
    font-size: 7vw;
  }

  .user-phone-numbers-buy-wrapper {
    padding: 30px 0;
  }
}