.cost-estimation-result-wrapper {
  text-align: center;
}

.cost-estimation-result-country, .cost-estimation-perspective-wrapper div > span {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.cost-estimation-breakdown-wrapper > p {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}


.cost-estimation-estimate > p {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.cost-estimation-breakdown strong {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.cost-estimation-breakdown p {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

@media (min-width: 1025px) {
  /* desktop styles */

  .cost-estimation-result-content {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .cost-estimation-result-country {
    font-size: 2vw;
  }

  .cost-estimation-breakdown-wrapper, .cost-estimation-perspective-wrapper, .cost-estimation-estimate {
    margin: 20px 20px;
    min-height: 31vh;
    padding: 10px;
  }

  .cost-estimation-perspective-wrapper div > span{
    display: block;
  }


  .cost-estimation-perspective-wrapper .text-input {
    margin: 10px 10px;
    width: 200px;
  }

  .cost-estimation-perspective-wrapper .button {
    margin: 10px 10px;
    width: 150px;
  }

  .cost-estimation-breakdown-wrapper > p {
    margin-top: 5px;
    font-size: 1.5vw;
  }

  .cost-estimation-estimate-breakdown-content {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .cost-estimation-estimate-breakdown-content > .cost-estimation-breakdown:first-child {
    border-right: 1px solid white;
  }

  .cost-estimation-breakdown {
    padding: 0 10px;
  }

  .cost-estimation-breakdown > div {
    margin: 10px 0 20px 0 ;
  }

  .cost-estimation-breakdown strong {
    font-size: 1vw;
  }

  .cost-estimation-breakdown p {
    font-size: 1vw;
  }

  .cost-estimation-estimate > p {
    margin-top: 40px;
    font-size: 1.5vw;
    text-decoration: underline;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .cost-estimation-result-content {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .cost-estimation-result-country {
    font-size: 4vw;
  }

  .cost-estimation-breakdown-wrapper, .cost-estimation-perspective-wrapper, .cost-estimation-estimate {
    margin-top: 20px;
    min-height: 31vh;
    padding: 10px;
  }

  .cost-estimation-perspective-wrapper div > span{
    display: block;
  }


  .cost-estimation-perspective-wrapper .text-input {
    margin: 10px 10px;
    width: 200px;
  }

  .cost-estimation-perspective-wrapper .button {
    margin: 10px 10px;
    width: 150px;
  }

  .cost-estimation-breakdown-wrapper > p {
    margin-top: 5px;
    font-size: 2vw;
  }

  .cost-estimation-estimate-breakdown-content {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .cost-estimation-estimate-breakdown-content > .cost-estimation-breakdown:first-child {
    border-right: 1px solid white;
  }

  .cost-estimation-breakdown {
    padding: 0 10px;
  }

  .cost-estimation-breakdown > div {
    margin: 10px 0 20px 0 ;
  }

  .cost-estimation-breakdown strong {
    font-size: 2vw;
  }

  .cost-estimation-breakdown p {
    font-size: 1.75vw;
  }


  .cost-estimation-estimate > p {
    margin-top: 40px;
    font-size: 2.5vw;
    text-decoration: underline;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .cost-estimation-result-content {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .cost-estimation-result-country {
    font-size: 4vw;
  }

  .cost-estimation-breakdown-wrapper, .cost-estimation-perspective-wrapper, .cost-estimation-estimate {
    margin-top: 20px;
    min-height: 31vh;
    padding: 10px;
  }

  .cost-estimation-perspective-wrapper div > span{
    display: block;
  }


  .cost-estimation-perspective-wrapper .text-input {
    margin: 10px 10px;
    width: 200px;
  }

  .cost-estimation-perspective-wrapper .button {
    margin: 10px 10px;
    width: 150px;
  }

  .cost-estimation-breakdown-wrapper > p {
    margin-top: 5px;
    font-size: 2vw;
  }

  .cost-estimation-estimate-breakdown-content {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .cost-estimation-estimate-breakdown-content > .cost-estimation-breakdown:first-child {
    border-right: 1px solid white;
  }

  .cost-estimation-breakdown {
    padding: 0 10px;
  }

  .cost-estimation-breakdown > div {
    margin: 10px 0 20px 0 ;
  }

  .cost-estimation-breakdown strong {
    font-size: 2vw;
  }

  .cost-estimation-breakdown p {
    font-size: 1.75vw;
  }


  .cost-estimation-estimate > p {
    margin-top: 40px;
    font-size: 2.5vw;
    text-decoration: underline;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .cost-estimation-result-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .cost-estimation-result-country {
    font-size: 7vw;
  }

  .cost-estimation-breakdown-wrapper {
    margin-top: 20px;
  }

  .cost-estimation-breakdown-wrapper > p {
    margin-top: 5px;
    font-size: 4vw;
  }
  /* estimate */

  .cost-estimation-perspective-wrapper {
    margin-top: 20px;
  }

  .cost-estimation-perspective-wrapper > div {
    margin: 10px 0
  }

  /* estimate result */
  .cost-estimation-estimate-breakdown-content .cost-estimation-breakdown {
    margin: 10px 5px;
  }

  .cost-estimation-estimate > p {
    margin: 30px 0;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .cost-estimation-result-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .cost-estimation-result-country {
    font-size: 7vw;
  }

  .cost-estimation-breakdown-wrapper {
    margin-top: 20px;
  }

  .cost-estimation-breakdown-wrapper > p {
    margin-top: 5px;
    font-size: 4vw;
  }

  /* estimate */

  .cost-estimation-perspective-wrapper {
    margin-top: 20px;
  }

  .cost-estimation-perspective-wrapper > div {
    margin: 10px 0
  }

  /* estimate result */
  .cost-estimation-estimate-breakdown-content .cost-estimation-breakdown {
    margin: 10px 5px;
  }

  .cost-estimation-estimate > p {
    margin: 30px 0;
  }
}