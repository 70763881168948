.text-area-input {
  width: 100%;
  height: auto;
  resize: none;
  border: 1px solid #979797;
  border-radius: 25px;
  background: transparent;
  font-size: 16px;
  line-height: 1.3;
  padding: 15px 0;
  outline: none;
  display: inline-block;
}
.text-area-input.invalid {
  background: #E0777D;
}
@media screen and (max-width: 500px) {
  .text-area-input {
    font-size: 14px;
    padding: 10px 0;
  }
}

.text-area-input::placeholder {
  color: black;
  text-align: center;
}
.text-area-input.invalid::placeholder {
  color: rgba(244, 250, 255, .5);
  text-align: center;
}