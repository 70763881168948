.entrypoints-heading-wrapper {
  text-align: center;
}

.entrypoints-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 500;
}

.entrypoints-links-wrapper {
  text-align: center;
}

.entrypoints-link {
  font-family: 'Raleway', sans-serif;
  display: block;
  text-decoration: none;
  color: black;
  background-color: #FFBE0B;
  margin: 0 auto;
  border-radius: 38px;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .entrypoints-wrapper {
    margin-top: 60px;
  }

  .entrypoints-heading {
    font-size: 1.5vw;
  }

  .entrypoints-links-wrapper {
    margin-top: 30px;
  }

  .entrypoints-links-wrapper > div {
    margin: 1vw;
  }
  .entrypoints-link {
    width: 160px;
    padding: 10px;
    font-size: 15px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .entrypoints-wrapper {
    margin-top: 60px;
  }

  .entrypoints-heading {
    font-size: 2.5vw;
  }

  .entrypoints-links-wrapper {
    margin-top: 30px;
  }

  .entrypoints-links-wrapper > div {
    margin: 2vw;
  }
  .entrypoints-link {
    width: 160px;
    padding: 10px;
    font-size: 15px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .entrypoints-wrapper {
    margin-top: 60px;
  }

  .entrypoints-heading {
    font-size: 2.5vw;
  }

  .entrypoints-links-wrapper {
    margin-top: 30px;
  }

  .entrypoints-links-wrapper > div {
    margin: 2vw;
  }
  .entrypoints-link {
    width: 160px;
    padding: 10px;
    font-size: 15px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .entrypoints-wrapper {
    margin-top: 40px;
  }

  .entrypoints-heading {
    font-size: 3.5vw;
  }

  .entrypoints-links-wrapper {
    margin-top: 30px;
  }

  .entrypoints-links-wrapper > div {
    margin: 5vw;
  }
  .entrypoints-link {
    width: 160px;
    padding: 10px;
    font-size: 15px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .entrypoints-wrapper {
    margin-top: 40px;
  }

  .entrypoints-heading {
    font-size: 3.5vw;
  }

  .entrypoints-links-wrapper {
    margin-top: 30px;
  }

  .entrypoints-links-wrapper > div {
    margin: 5vw;
  }
  .entrypoints-link {
    width: 160px;
    padding: 10px;
    font-size: 15px;
  }
}