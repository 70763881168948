.signupfee-info-wrapper {
  text-align: center;
}


.signupfee-info {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

.signupfee-price {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.signupfee-info-controls {
  text-align: center;
}

.signupfee-disagree-button, .signupfee-agree-button {
  display: inline-block;
  margin: 0 2vw 0 2vw;
}

.signupfee-disagree-button {
  color: #FFBE0B;
  background-color: white;
}

.signupfee-agree-button {
  color: white;
  background: linear-gradient(180deg, #8338EC 0%, rgba(58, 134, 255, 0.78) 100%);
}

.signupfee-agree-button:hover {
  background:linear-gradient(180deg, #b68af4 0%, rgba(58, 134, 255, 0.78) 100%);
}

.signupfee-disagree-button:hover {
  color: red;
  background-color: white;
}



@media (min-width: 1025px) {
  /* desktop styles */
  .signupfee-info-wrapper {
    margin: 3vw 12vw 0 12vw;

  }

  .signupfee-info {
    font-size: 1.5vw;
    margin-bottom: 10px;
  }

  .signupfee-price {
    font-size: 1.5vw;
  }

  .signupfee-info-controls {
    margin-top: 3vw;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .signupfee-info-wrapper {
    margin: 3vw 12vw 0 12vw;

  }

  .signupfee-info {
    font-size: 2.5w;
    margin-bottom: 10px;
  }

  .signupfee-price {
    font-size: 2vw;
  }

  .signupfee-info-controls {
    margin-top: 6vw;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .signupfee-info-wrapper {
    margin: 3vw 12vw 0 12vw;

  }

  .signupfee-info {
    font-size: 2.5vw;
    margin-bottom: 10px;
  }

  .signupfee-price {
    font-size: 2vw;
  }

  .signupfee-info-controls {
    margin-top: 6vw;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .signupfee-info-wrapper {
    margin: 3vw 12vw 0 12vw;

  }

  .signupfee-info {
    font-size: 3.5vw;
    margin-bottom: 10px;
  }

  .signupfee-price {
    font-size: 3vw;
  }

  .signupfee-info-controls {
    margin-top: 6vw;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .signupfee-info-wrapper {
    margin: 3vw 12vw 0 12vw;

  }

  .signupfee-info {
    font-size: 3.5vw;
    margin-bottom: 10px;
  }

  .signupfee-price {
    font-size: 3vw;
  }

  .signupfee-info-controls {
    margin-top: 6vw;
  }
}