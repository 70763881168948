.around-the-world-wrapper {
  background-color: #8338EC;
  height: 100vh;
  position: relative;
}

.around-the-world-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.around-the-world-subheading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .around-the-world-wrapper {
    padding: 10px;
  }

  .around-the-world-heading-wrapper {
    position: absolute;
    left: 30%;
    top: 16%;
  }

  .around-the-world-heading {
    max-width: 35vw;
    margin: 0 0 20px 0;
    font-size: 2vw;
  }

  .around-the-world-subheading {
    max-width: 33vw;
    margin: 0 0 10px 0;
    font-size: 1.5vw;
  }

  .around-the-world-asset-phone {
    height: auto;
    width: 20vw;
    position: absolute;
    left: 8%;
    top: 16%;
  }

  .around-the-world-asset-wheel {
    height: auto;
    width: 25vw;
    position: absolute;
    left: 30%;
    top: 36%;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .around-the-world-wrapper {
    padding: 10px;
  }

  .around-the-world-heading-wrapper {
    position: absolute;
    left: 40%;
    top: 25%;
  }

  .around-the-world-heading {
    max-width: 40vw;
    margin: 0 0 10px 0;
    font-size: 4vw;
  }

  .around-the-world-subheading {
    max-width: 40vw;
    margin: 0 0 10px 0;
    font-size: 2vw;
  }

  .around-the-world-asset-phone {
    height: auto;
    width: 31vw;
    position: absolute;
    left: 9%;
    top: 25%;
  }

  .around-the-world-asset-wheel {
    height: auto;
    width: 30vw;
    position: absolute;
    left: 40%;
    top: 45%;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .around-the-world-wrapper {
    padding: 10px;
  }

  .around-the-world-heading-wrapper {
    position: absolute;
    left: 40%;
    top: 20%;
  }

  .around-the-world-heading {
    max-width: 40vw;
    margin: 0 0 10px 0;
    font-size: 4vw;
  }

  .around-the-world-subheading {
    max-width: 40vw;
    margin: 0 0 10px 0;
    font-size: 2vw;
  }

  .around-the-world-asset-phone {
    height: auto;
    width: 31vw;
    position: absolute;
    left: 9%;
    top: 20%;
  }

  .around-the-world-asset-wheel {
    height: auto;
    width: 30vw;
    position: absolute;
    left: 40%;
    top: 38%;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .around-the-world-wrapper {
    padding: 10px;
  }

  .around-the-world-heading-wrapper {
    position: absolute;
    left: 5%;
    top: 4%;
  }

  .around-the-world-heading {
    max-width: 60vw;
    margin: 0 0 10px 0;
    font-size: 4vw;
    line-height: 22px;
  }

  .around-the-world-subheading {
    max-width: 45vw;
    margin: 0 0 10px 0;
    font-size: 3vw;
    line-height: 20px;
  }

  .around-the-world-asset-phone {
    height: auto;
    width: 50vw;
    position: absolute;
    left: 26%;
    top: 35%;
  }

  .around-the-world-asset-wheel {
    height: auto;
    width: 30vw;
    position: absolute;
    left: 60%;
    top: 6%;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .around-the-world-wrapper {
    padding: 10px;
  }

  .around-the-world-heading-wrapper {
    position: absolute;
    left: 5%;
    top: 4%;
  }

  .around-the-world-heading {
    max-width: 60vw;
    margin: 0 0 10px 0;
    font-size: 4vw;
    line-height: 22px;
  }

  .around-the-world-subheading {
    max-width: 45vw;
    margin: 0 0 10px 0;
    font-size: 3vw;
    line-height: 20px;
  }

  .around-the-world-asset-phone {
    height: auto;
    width: 50vw;
    position: absolute;
    left: 26%;
    top: 35%;
  }

  .around-the-world-asset-wheel {
    height: auto;
    width: 30vw;
    position: absolute;
    left: 60%;
    top: 6%;
  }
}