.payment-intent-card-wrapper, .payment-intent-wizard-info-wrapper {
  text-align: center;
}

.payment-intent-wizard-info {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 300;
}



@media (min-width: 1025px) {
  /* desktop styles */
  .payment-intent-wizard-info-wrapper {
    margin-top: 20px;
    font-size: 1vw;
  }

  .payment-intent-card-wrapper {
    margin-top: 50px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .payment-intent-wizard-info-wrapper {
    margin-top: 20px;
    font-size: 2vw;
  }

  .payment-intent-card-wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .payment-intent-wizard-info-wrapper {
    margin-top: 20px;
    font-size: 2vw;
  }

  .payment-intent-card-wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .payment-intent-wizard-info-wrapper {
    margin-top: 20px;
    font-size: 4vw;
  }

  .payment-intent-card-wrapper {
    margin-top: 50px;
    padding: 0 10px 0 10px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .payment-intent-wizard-info-wrapper {
    margin-top: 20px;
    font-size: 3.5vw;
  }

  .payment-intent-card-wrapper {
    margin-top: 50px;
    padding: 0 10px 0 10px;
  }
}











