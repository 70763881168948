.navbarlinks-link {
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  color: black;
  font-weight: 400;
}

.navbarlinks-wrapper {
  text-align: right;
}

.login {
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  color: black;
  background: #8338EC;
  border-radius: 20px;
  color: white;
}


@media (min-width: 1025px) {
  /* desktop styles */
  .navbarlinks-link, .login {
    font-size: 25px;
    margin: 0 1vw 0 1vw;
  }

  .login {
    padding: 10px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .navbarlinks-link {
    margin: 0 2vw 0 1vw;
    font-size: 2vw;
  }

  .login {
    font-size: 1.5vw;
    padding: 2vw;
    margin: 0 25px 0 25px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .navbarlinks-link {
    margin: 0 2vw 0 1vw;
    font-size: 2vw;
  }

  .login {
    font-size: 1.5vw;
    padding: 2vw;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .navbarlinks-link {
    font-family: 'Raleway', sans-serif;
    text-decoration: none;
    color: white;
    font-weight: 400;
  }

  .navbarlinks-wrapper > .navbarlinks-link {
    display: block;
    text-align: left;
    margin: 10vw 0 10vw 0;
  }


  .navbarlinks-wrapper > .login {
    text-align: center;
  }

  .login {
    font-size: 5vw;
    padding: 3vw;
    display: block;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .navbarlinks-link {
    font-family: 'Raleway', sans-serif;
    text-decoration: none;
    color: white;
    font-weight: 400;
  }

  .navbarlinks-wrapper > .navbarlinks-link {
    display: block;
    text-align: left;
    margin: 10vw 0 10vw 0;
  }


  .navbarlinks-wrapper > .login {
    text-align: center;
  }

  .login {
    font-size: 5vw;
    padding: 3vw;
    display: block;
  }
}