.landing-container-img {
  height: auto;
  width: 100vw;
  display: block;
}

.landing-container-img-wrapper {
  position: relative;
  overflow: hidden;
}

.landing-container-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}
.dark {
  color: black !important;
}

.landing-container-text {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 500;
}

.landing-container-iphone {
  height: auto;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .landing-container-heading {
    font-size: 2vw;
  }

  .landing-container-text {
    font-size: 1.2vw;
  }

  .landing-container-intro {
    position: absolute;
    top: 10%;
    left: 15%;
    width: 30vw;
    text-align: left;
  }

  .landing-container-intro > p {
    margin-top: 20px;
  }

  .landing-container-intro > div {
    margin-top: 60px;
  }

  .landing-container-iphone {
    width: 25vw;
    position: absolute;
    top: 10%;
    left: 62%;
  }

  .landing-container-pricing-text-block > .landing-container-text {
    margin-top: 1vw;
  }

  .landing-container-testimonials {
    position: absolute;
    bottom: 25%;
    left: 36%;
    width: 30vw;
    text-align: center;
    display: block;
  }

  .override-position {
    top: 3% !important;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .landing-container-heading {
    font-size: 3vw;
    line-height: 30px;
  }

  .landing-container-text {
    font-size: 1.5vw;
  }

  .landing-container-intro {
    position: absolute;
    top: 5%;
    left: 15%;
    width: 40vw;
    text-align: left;
  }

  .landing-container-intro > p {
    margin-top: 20px;
  }

  .landing-container-intro > div {
    margin-top: 60px;
  }

  .landing-container-iphone {
    width: 25vw;
    position: absolute;
    top: 20%;
    left: 62%;
  }

  .landing-container-pricing-text-block > .landing-container-text {
    margin-top: 1vw;
  }

  .landing-container-testimonials {
    position: absolute;
    bottom: 25%;
    left: 36%;
    width: 30vw;
    text-align: center;
    display: block;
  }

  .override-position {
    top: 0 !important;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .landing-container-heading {
    font-size: 3vw;
    line-height: 30px;
  }

  .landing-container-text {
    font-size: 1.5vw;
  }

  .landing-container-intro {
    position: absolute;
    top: 5%;
    left: 15%;
    width: 40vw;
    text-align: left;
  }

  .override-position {
    top: 0 !important;
  }

  .landing-container-intro > p {
    margin-top: 20px;
  }

  .landing-container-intro > div {
    margin-top: 60px;
  }

  .landing-container-iphone {
    width: 25vw;
    position: absolute;
    top: 20%;
    left: 62%;
  }

  .landing-container-pricing-text-block > .landing-container-text {
    margin-top: 1vw;
  }

  .landing-container-testimonials {
    position: absolute;
    bottom: 25%;
    left: 36%;
    width: 30vw;
    text-align: center;
    display: block;
  }

  .override-position {
    top: 0 !important;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .landing-container-heading {
    font-size: 5.3vw;
    line-height: 25px;
  }

  .landing-container-text {
    font-size: 3.5vw;
  }

  .landing-container-intro {
    position: absolute;
    top: 7%;
    left: 0;
    margin: 0 10vw;
    text-align: center;
  }

  .override-position {
    top: 0 !important;
  }

  .landing-container-intro > p {
    margin-top: 10px;
  }

  .landing-container-intro > div {
    margin-top: 60px;
  }

  .landing-container-intro > div > .btn-anchor {
    width: 30px;
    padding: 15px;
    font-size: 4vw;
  }

  .landing-container-intro > div > .purple-btn {
    background-color: #8338EC;
  }

  .landing-container-iphone {
    width: 55vw;
    position: absolute;
    top: 36%;
    left: 26%;
  }

  .landing-container-pricing-text-block > .landing-container-text {
    margin-top: 1vw;
  }

  .landing-container-testimonials {
    position: absolute;
    bottom: 3%;
    left: 36%;
    width: 30vw;
    text-align: center;
    display: block;
  }

  .override-position {
    top: 0 !important;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .landing-container-heading {
    font-size: 5.3vw;
    line-height: 25px;
  }

  .landing-container-text {
    font-size: 3.5vw;
  }

  .landing-container-intro {
    position: absolute;
    top: 7%;
    left: 0;
    margin: 0 10vw;
    text-align: center;
  }

  .override-position {
    top: 0 !important;
  }

  .landing-container-intro > p {
    margin-top: 10px;
  }

  .landing-container-intro > div {
    margin-top: 60px;
  }

  .landing-container-intro > div > .btn-anchor {
    width: 30px;
    padding: 15px;
    font-size: 4vw;
  }

  .landing-container-intro > div > .purple-btn {
    background-color: #8338EC;
  }

  .landing-container-iphone {
    width: 55vw;
    position: absolute;
    top: 36%;
    left: 26%;
  }

  .landing-container-pricing-text-block > .landing-container-text {
    margin-top: 1vw;
  }

  .landing-container-testimonials {
    position: absolute;
    bottom: 3%;
    left: 36%;
    width: 30vw;
    text-align: center;
    display: block;
  }

  .override-position {
    top: 0 !important;
  }
}