.credit-card-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.credit-card-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  /* Rectangle 2 */


  background: linear-gradient(180deg, #8338EC 0%, rgba(58, 134, 255, 0.78) 100%);

  border-radius: 25px;
  margin-bottom: 40px;
}

.credit-card-form-row {
  height: 70px;
  display: flex;
  flex-wrap: nowrap;
}

/* Stripe Element */
.StripeElement {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: text;
}
.StripeElement * {
  width: 100%;
}

/* Credit Card Number */
.credit-card-number-block {
  height: 80px;
  border-bottom: 1px solid #979797;
  display: flex;
  align-items: center;
  cursor: text;
}

/* Credit Card Expiry Date */
.credit-card-expiry-block {
  width: 50%;
  border-right: 1px solid #979797;
}

/* Credit Card CVC */
.credit-card-cvc-block {
  width: 50%;
}

.credit-card-form-disclaimer {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
  margin: 20px;
}

.credit-card-form-disclaimer > a {
  text-decoration: underline;
  font-weight: 750;
  color: white;
}

/* Submit Button */
.credit-card-form-submit-btn {
  width: 100%;
  background: linear-gradient(180deg, #8338EC 0%, rgba(58, 134, 255, 0.78) 100%);
}

.credit-card-form-submit-btn:hover {
  background:linear-gradient(180deg, #b68af4 0%, rgba(58, 134, 255, 0.78) 100%);
}


/* MOBILE */

.app.mobile .credit-card-form-row {
  height: 60px;
}

/* Stripe Element */
.app.mobile .StripeElement {
  padding: 0 20px;
}

/* Credit Card Number */
.app.mobile .credit-card-number-block {
  height: 60px;
}


@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */

  .credit-card-form-disclaimer, .credit-card-form-disclaimer {
    font-size: 4vw;
  }
  
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */

  .credit-card-form-disclaimer, .credit-card-form-disclaimer {
    font-size: 4vw;
  }
  
}