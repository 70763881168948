@keyframes ldio {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loadingio-spinner-eclipse .ldio {
  overflow: initial !important;
}

/* X-SMALL */
.loadingio-spinner-eclipse.x-small {
  width: 15px;
  height: 15px;
}
.loadingio-spinner-eclipse.x-small .ldio div {
  position: absolute;
  animation: ldio 1s linear infinite;
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 0 0 #f4faff;
  transform-origin: 40px 41px;
}
.loadingio-spinner-eclipse.x-small .loadingio-spinner-eclipse {
  width: 15px;
  height: 15px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loadingio-spinner-eclipse.x-small .ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.15);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.loadingio-spinner-eclipse.x-small .ldio div { box-sizing: content-box; }


/* SMALL */
.loadingio-spinner-eclipse.small {
  width: 30px;
  height: 30px;
}
.loadingio-spinner-eclipse.small .ldio div {
  position: absolute;
  animation: ldio 1s linear infinite;
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 0 0 #f4faff;
  transform-origin: 40px 41px;
}
.loadingio-spinner-eclipse.small .loadingio-spinner-eclipse {
  width: 30px;
  height: 30px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loadingio-spinner-eclipse.small .ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.3);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.loadingio-spinner-eclipse.small .ldio div { box-sizing: content-box; }


/* MEDIUM */
.loadingio-spinner-eclipse.medium {
  width: 75px;
  height: 75px;
}
.loadingio-spinner-eclipse.medium .ldio div {
  position: absolute;
  animation: ldio 1s linear infinite;
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 0 0 #f4faff;
  transform-origin: 40px 41px;
}
.loadingio-spinner-eclipse.medium .loadingio-spinner-eclipse {
  width: 75px;
  height: 75px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.loadingio-spinner-eclipse.medium .ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.75);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.loadingio-spinner-eclipse.medium .ldio div { box-sizing: content-box; }


/* BIG */
.loadingio-spinner-eclipse.big {
  width: 100px;
  height: 100px;
}
.loadingio-spinner-eclipse.big .ldio div {
  position: absolute;
  animation: ldio 1s linear infinite;
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 0 0 #f4faff;
  transform-origin: 40px 41px;
}
.loadingio-spinner-eclipse.big .loadingio-spinner-eclipse {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.loadingio-spinner-eclipse.big .ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.loadingio-spinner-eclipse.big .ldio div { box-sizing: content-box; }