.message-wrapper {
  border-radius: 38px;
}

.message-content-wrapper > p {
  white-space: pre-line;
}

.outgoing-msg {
  background-color: gray;
}

.incoming-msg {
  background-color: #b68af4;
}

.message-content-wrapper {
  text-align: left;
}

.message-meta-wrapper {
  text-align: right;
}
@media (min-width: 1025px) {
  /* desktop styles */
  .message-wrapper {
    padding: 20px;
    margin-top: 20px;
  }

  .outgoing-msg {
    margin-left: 60%;
  }

  .incoming-msg {
    margin-right: 60%;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .message-wrapper {
    padding: 20px;
    margin-top: 20px;
  }

  .outgoing-msg {
    margin-left: 60%;
  }

  .incoming-msg {
    margin-right: 60%;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .message-wrapper {
    padding: 20px;
    margin-top: 20px;
  }

  .outgoing-msg {
    margin-left: 60%;
  }

  .incoming-msg {
    margin-right: 60%;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .message-wrapper {
    padding: 20px;
    margin-top: 20px;
  }

  .outgoing-msg {
    margin-left: 35%;
  }

  .incoming-msg {
    margin-right: 35%;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .message-wrapper {
    padding: 20px;
    margin-top: 20px;
  }

  .outgoing-msg {
    margin-left: 30%;
  }

  .incoming-msg {
    margin-right: 30%;
  }
}