.buy-numbers-container {
  height: 100%;
  min-height: 100vh;
  background-color: #8338EC;
}

.buy-numbers-container-heading-wrapper {
  text-align: center;
}

.buy-numbers-container-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.buy-numbers-container-subheading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

.buy-numbers-container-phone-numbers-wrapper {
  text-align: center;
}

.buy-numbers-button-wrapper {
  text-align: center;
}



@media (min-width: 1025px) {
  /* desktop styles */
  .buy-numbers-container-heading-wrapper {
    padding-top: 50px;
  }

  .buy-numbers-container-heading {
    font-size: 2vw;
  }

  .buy-numbers-container-subheading {
    font-size: 1.5vw;
  }

  .buy-numbers-container-country-select-wrapper {
    padding: 50px;
  }

  .buy-numbers-container-phone-numbers-wrapper {
    margin: 50px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .buy-numbers-container-heading-wrapper {
    padding-top: 50px;
  }

  .buy-numbers-container-heading {
    font-size: 4vw;
  }

  .buy-numbers-container-subheading {
    font-size: 2vw;
  }

  .buy-numbers-container-country-select-wrapper {
    padding: 50px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .buy-numbers-container-heading-wrapper {
    padding-top: 50px;
  }

  .buy-numbers-container-heading {
    font-size: 4vw;
  }

  .buy-numbers-container-subheading {
    font-size: 2vw;
  }

  .buy-numbers-container-country-select-wrapper {
    padding: 50px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .buy-numbers-container-heading-wrapper {
    padding-top: 30px;
  }

  .buy-numbers-container-heading {
    font-size: 7vw;
  }

  .buy-numbers-container-subheading {
    font-size: 4vw;
  }

  .buy-numbers-container-country-select-wrapper {
    padding: 30px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .buy-numbers-container-heading-wrapper {
    padding-top: 30px;
  }

  .buy-numbers-container-heading {
    font-size: 7vw;
  }

  .buy-numbers-container-subheading {
    font-size: 4vw;
  }

  .buy-numbers-container-country-select-wrapper {
    padding: 30px;
  }
}