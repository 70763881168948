.phone-number-buy-failure-info {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

.phone-number-buy-failure-info-wrapper {
  text-align: center;
}

.phone-number-buy-failure-controls-wrapper {
  text-align: center;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .phone-number-buy-failure-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-failure-info {
    font-size: 1.5vw;
  }

  .phone-number-buy-failure-controls-wrapper {
    margin-top: 50px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .phone-number-buy-failure-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-failure-info {
    font-size: 2vw;
  }

  .phone-number-buy-failure-controls-wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .phone-number-buy-failure-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-failure-info {
    font-size: 2vw;
  }

  .phone-number-buy-failure-controls-wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .phone-number-buy-failure-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-failure-info {
    font-size: 4vw;
  }

  .phone-number-buy-failure-controls-wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .phone-number-buy-failure-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-failure-info {
    font-size: 4vw;
  }

  .phone-number-buy-failure-controls-wrapper {
    margin-top: 50px;
  }
}