.text-input {
  width: 330px;
  height: 60px;
  border: 1px solid #979797;
  border-radius: 25px;
  background: #8338EC;
  font-size: 22px;
  color: #F4FAFF;
  text-align: center;
  padding: 0 20px;
  outline: none;
  margin: 0 auto 40px;
  transition: all .2s ease-in-out;
}
.text-input.invalid {
  background: #E0777D;
}

.text-input-controls-wrapper {
  position: relative;
}

.text-input-controls-wrapper > .text-input {
  margin: 0 auto 0;
  height: 45px;
}

.text-input-clear {
  position: absolute;
  top: 25%;
  left: 90%;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .text-input {
    height: 40px;
    width: 70%;
    border-radius: 18px;
    padding: 0 15px;
    font-size: 16px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1025px) {
  /* desktop styles */
  .text-input {
    width: 80%;
    font-size: 15px;
    height: 60px;
  }
}

@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .text-input {
    width: 80%;
    font-size: 15px;
    height: 60px;
  }
}

.text-input::placeholder {
  color: rgba(244, 250, 255, .35)
}
.text-input.invalid::placeholder {
  color: rgba(244, 250, 255, .5)
}