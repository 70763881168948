.mobile-navbar-wrapper {
  overflow: hidden;
  z-index: 5;
  position: fixed; 
  top: 0; 
  min-height: 4vh;
  width: 100%; 
  background-color: white;
}

.mobile-navbar-content-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}

.mobile-navbar-brand-wrapper {
  text-align: left;
}

.mobile-navbar-hamburger-wrapper {
  text-align: right;
  align-self: center;
}

.mobile-navbar-hamburger {
  display: inline-block;
}

.mobile-navbar-brand-link:active > img {
  transform: scale(1.2);
}

.mobile-navbar-hamburger-wrapper:active {
  display: inline-block;
  transform: scale(1.2);
}

.mobile-navbar-hamburger-layer {
  background-color: #8338EC;
  border-radius: 5px;
}


@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .mobile-navbar-wrapper {
    padding: 5px 0 5px 0;
  }

  .mobile-navbar-content-wrapper {
    padding: 0 10px 0 10px;
  }

  .mobile-navbar-hamburger :not(:first-child){
    margin-top: 8px;
  }

  .mobile-navbar-hamburger-layer {
    height: 2px;
    width: 40px;
  }

  .mobile-navbar-brand-logo {
    width: auto;
    height: 45px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */

  .mobile-navbar-wrapper {
    padding: 5px 0 5px 0;
  }

  .mobile-navbar-content-wrapper {
    padding: 0 10px 0 10px;
  }

  .mobile-navbar-hamburger :not(:first-child){
    margin-top: 8px;
  }

  .mobile-navbar-hamburger-layer {
    height: 2px;
    width: 40px;
  }

  .mobile-navbar-brand-logo {
    width: auto;
    height: 40px;
  }
}
