.new-phone-number-conversation-wrapper {
  min-height: 100vh;
  max-height: 100%;
  background-color: #8338EC;
  padding-bottom: 30px;
}

.new-phone-number-conversation-outgoing-number {
  text-align: center;
}

.new-phone-number-conversation-outgoing-number > .phone-number-list-item-wrapper {
  cursor: default;
}

@media (min-width: 1025px) {
  /* desktop styles */
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  
}