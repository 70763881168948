.real-time-wrapper {
  background-color: #8338EC;
  height: 100vh;
  position: relative;
}

.real-time-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.real-time-subheading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .real-time-heading-wrapper {
    text-align: center;
    padding-top: 80px;
  }

  .real-time-heading {
    font-size: 2vw;
    margin: 0 0 10px 0;
  }

  .real-time-subheading {
    font-size: 1.5vw;
  }

  .real-time-image {
    position: absolute;
    top: 40%;
    left: 36%;
    height: auto;
    width: 25vw;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .real-time-heading-wrapper {
    text-align: center;
    padding-top: 130px;
  }

  .real-time-heading {
    font-size: 4vw;
    margin: 0 0 10px 0;
  }

  .real-time-subheading {
    font-size: 2vw;
  }

  .real-time-image {
    position: absolute;
    top: 40%;
    left: 30%;
    height: auto;
    width: 48vw;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .real-time-heading-wrapper {
    text-align: center;
    padding-top: 130px;
  }

  .real-time-heading {
    font-size: 4vw;
    margin: 0 0 10px 0;
  }

  .real-time-subheading {
    font-size: 2vw;
  }

  .real-time-image {
    position: absolute;
    top: 40%;
    left: 30%;
    height: auto;
    width: 48vw;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .real-time-heading-wrapper {
    text-align: center;
    padding-top: 40px;
  }

  .real-time-heading {
    font-size: 4vw;
    margin: 0 0 10px 0;
  }

  .real-time-subheading {
    font-size: 3vw;
    margin: 0 8vw;
  }

  .real-time-image {
    position: absolute;
    top: 40%;
    left: 20%;
    height: auto;
    width: 60vw;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .real-time-heading-wrapper {
    text-align: center;
    padding-top: 40px;
  }

  .real-time-heading {
    font-size: 4vw;
    margin: 0 0 10px 0;
  }

  .real-time-subheading {
    font-size: 3vw;
    margin: 0 8vw;
  }

  .real-time-image {
    position: absolute;
    top: 40%;
    left: 20%;
    height: auto;
    width: 60vw;
  }
}