.conversation-list-item {
  cursor: pointer;
  background-color: white;
  border-radius: 38px;
  color: black;
  border: 1px solid #8338EC;
}

.conversation-selected {
  background-color: #8338EC;
  color: white;
}

.conversation-list-item-meta {
  display: grid;
  grid-template-columns: 95% 5%;
}

.conversation-list-item-meta > .conversation-list-item-meta-content {
  text-align: right;
}

.conversation-list-item-heading {
  font-family: 'Raleway', sans-serif;
  color: inherit;
  font-weight: 600;
}

.conversation-list-item-meta-content, .conversation-list-item-meta-recipient {
  font-family: 'Raleway', sans-serif;
  color: inherit;
  font-weight: 400;
  align-self: center;
}

.conversation-list-item-meta-recipient {
  font-weight: 600;
}

.conversation-list-item-meta-control {
  text-align: right;
}

.conversation-list-item-meta-control > .mui-dropdown > .mui-btn   {
  color: inherit;
}

.conversation-list-item-status-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  
}


@media (min-width: 1025px) {
  /* desktop styles */
  .conversation-list-item {
    margin: 30px 20px;
  }

  .conversation-list-item-content {
    padding: 20px;
  }

  .conversation-list-item-title-wrapper {
    margin: 10px 0;
  }

  .conversation-list-item-heading {
    font-size: 1.2vw;
  }

  .conversation-list-item-content, .conversation-list-item-expanded-content {
    padding: 20px;
  }

  .conversation-list-item-meta-content, .conversation-list-item-meta-recipient {
    font-size: 0.8vw;
  }

  .conversation-list-item-status-wrapper {
    margin-top: 10px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .conversation-list-item {
    margin: 30px 20px;
  }

  .conversation-list-item-content, .conversation-list-item-expanded-content {
    padding: 20px;
  }

  .conversation-list-item-heading {
    font-size: 2vw;
  }

  .conversation-list-item-content, .conversation-list-item-expanded-content {
    padding: 20px;
  }

  .conversation-list-item-meta-content, .conversation-list-item-meta-recipient {
    font-size: 1.2vw;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .conversation-list-item {
    margin: 30px 20px;
  }

  .conversation-list-item-content, .conversation-list-item-expanded-content {
    padding: 20px;
  }

  .conversation-list-item-heading {
    font-size: 2vw;
  }

  .conversation-list-item-content, .conversation-list-item-expanded-content {
    padding: 20px;
  }

  .conversation-list-item-meta-content, .conversation-list-item-meta-recipient {
    font-size: 1.2vw;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .conversation-list-item {
    margin: 30px 20px;
  }

  .conversation-list-item-content, .conversation-list-item-expanded-content {
    padding: 5px 15px;
  }

  .conversation-list-item-heading {
    font-size: 5vw;
  }

  .conversation-list-item-meta-content, .conversation-list-item-meta-recipient {
    font-size: 3vw;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .conversation-list-item {
    margin: 30px 20px;
  }

  .conversation-list-item-content, .conversation-list-item-expanded-content {
    padding: 5px 12px;
  }

  .conversation-list-item-heading {
    font-size: 5vw;
  }

  .conversation-list-item-meta-content, .conversation-list-item-meta-recipient {
    font-size: 3.2vw;
  }
}