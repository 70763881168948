.mui-btn {
  background: none;
  margin: 0;
  padding: 0 10px;
  color: white;
  transition: color .2s ease-in-out;
}
.mui-btn:hover,
.mui-btn:focus,
.mui-btn:active {
  background: none;
  box-shadow: none !important;
}

.mui-btn__ripple-container {
  display: none;
}

.mui-dropdown__menu {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 2px 10px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  z-index: 2;
}

.mui-dropdown__menu > li {
  cursor: pointer;
}
.mui-dropdown__menu > li > a:hover,
.mui-dropdown__menu > li > a:focus,
.mui-dropdown__menu > li.dropdown-menu-item-selected {
  background: #d6dbdc;
}