.full-size-conversation-index-wrapper {
  display: grid;
  position: relative;
}

.full-size-conversation-list-wrapper {
  overflow-y: scroll;
}

.full-size-conversation-index-messages-wrapper {
  position: relative;
  height: 100%;
}

@media (min-width: 1025px) {
  /* desktop styles */

  .full-size-conversation-index-wrapper {
    grid-template-columns: 30% 70%;
  }

  .full-size-conversation-index-wrapper {
    min-height: 90vh;
  }
  
  .full-size-conversation-index-header-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
  }


  .full-size-conversation-index-header-wrapper {
    margin: 10px;
  }

  .full-size-conversation-index-header-wrapper > div {
    align-self: center;
    margin: 0 10px;
  }

  .full-size-conversation-index-header-wrapper > div > .text-input {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-self: center;
    height: 40px;
  }

  .full-size-conversation-index-header-wrapper > div > .btn-anchor {
    width: 20%;
    margin: 0 auto;
    align-self: center;
    padding: 13px;
    height: 50px;
  }

  .full-size-conversation-list-wrapper {
    height: 80vh;
  }

  .full-size-conversation-index-messages-wrapper {
    margin-top: 35px;
  }

  /* .full-size-conversation-index-messages-wrapper > .conversation-wrapper{
    position: absolute;
    width: 100%;
    bottom: 0;
  } */


  .full-size-conversation-index-messages-wrapper > .conversation-wrapper > .conversation-messages-wrapper {
    max-height: 80vh;
  }
  
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */

  .full-size-conversation-index-wrapper {
    grid-template-columns: 40% 60%;
  }

  .full-size-conversation-index-header-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .full-size-conversation-index-header-wrapper {
    margin: 10px;
  }

  .full-size-conversation-index-header-wrapper > div {
    align-self: center;
    margin: 10px;
  }

  .full-size-conversation-index-header-wrapper > div > .text-input {
    width: 50px;
    margin: 0 auto;
    display: flex;
    align-self: center;
    height: 40px;
  }

  .full-size-conversation-index-header-wrapper > div > .btn-anchor {
    width: 50px;
    margin: 0 auto;
    align-self: center;
    padding: 13px;
    height: 50px;
  }

  .full-size-conversation-list-wrapper {
    max-height: 76vh;
    min-height: 50vh;
  }

  .full-size-conversation-index-messages-wrapper > .conversation-wrapper{
    position: absolute;
    width: 100%;
    bottom: 0;
  }


  .full-size-conversation-index-messages-wrapper > .conversation-wrapper > .conversation-messages-wrapper {
    max-height: 80vh;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .full-size-conversation-index-header-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .full-size-conversation-index-header-wrapper {
    margin: 10px;
  }

  .full-size-conversation-index-header-wrapper > div {
    align-self: center;
    margin: 10px;
  }

  .full-size-conversation-index-header-wrapper > div > .text-input {
    width: 50px;
    margin: 0 auto;
    display: flex;
    align-self: center;
    height: 40px;
  }

  .full-size-conversation-index-header-wrapper > div > .btn-anchor {
    width: 50px;
    margin: 0 auto;
    align-self: center;
    padding: 13px;
    height: 50px;
  }

  .full-size-conversation-list-wrapper {
    max-height: 76vh;
    min-height: 50vh;
  }

  .full-size-conversation-index-messages-wrapper > .conversation-wrapper{
    position: absolute;
    width: 100%;
    bottom: 0;
  }


  .full-size-conversation-index-messages-wrapper > .conversation-wrapper > .conversation-messages-wrapper {
    max-height: 80vh;
  }
}
