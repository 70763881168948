.error-box {
  width: 100%;
  color: #F4E285;
  text-shadow: 1px 1px rgba(0, 0, 0, .5);
  text-align: center;
  font-size: 22px;
  margin-bottom: 50px;
}
@media screen and (max-width: 500px) {
  .error-box {
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 1.8;
  }
}