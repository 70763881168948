.conversation-messages-wrapper {
  text-align: center;
  max-height: 50vh;
  overflow-y: scroll;
}

.conversation-controls-wrapper {
  display: grid;
}

.conversation-controls-wrapper > div {
  align-self: center;
}

@media (min-width: 1025px) {
  /* desktop styles */

  .conversation-controls-wrapper {
    /* margin-top: 10px; */
    /* grid-template-columns: 80% 20%; */
  }

  .conversation-controls-wrapper > div {
    margin: 0 30px;
  }

  .messages-wrapper-extra-padding {
   padding: 25px; 
  }
  .controls-override {
    /* text-align: center; */
  }

  .controls-override div {
    margin-bottom: 35px;
  }

  .controls-override div > .next-button {
    min-width: 0;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .conversation-controls-wrapper {
    margin-top: 10px;
    grid-template-columns: 70% 30%;
  }

  .conversation-controls-wrapper > div {
    margin: 0 5px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .conversation-controls-wrapper {
    margin-top: 10px;
    grid-template-columns: 75% 25%;
  }

  .conversation-controls-wrapper > div {
    margin: 0 5px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .conversation-controls-wrapper {
    margin-top: 10px;
    grid-template-columns: 70% 30%;
  }

  .conversation-controls-wrapper > div {
    margin: 0 8px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .conversation-controls-wrapper {
    margin-top: 10px;
    grid-template-columns: 70% 30%;
  }

  .conversation-controls-wrapper > div {
    margin: 0 4px;
  }
}