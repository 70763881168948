.phone-number-buy-confirmation-wrapper {
  text-align: center;
}

.phone-number-buy-confirmation-header {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.phone-number-buy-confirmation-info {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

.phone-number-buy-confirmation-total-billable {
  font-weight: 650;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: white;
}


@media (min-width: 1025px) {
  /* desktop styles */
  .phone-number-buy-confirmation-header-wrapper {
    padding: 20px;
  }

  .phone-number-buy-confirmation-header {
    font-size: 2vw;
  }

  .phone-number-buy-confirmation-info {
    margin: 20px;
    font-size: 1.5vw;
  }

  .phone-number-buy-confirmation-total-billable {
    margin: 30px;
    font-size: 1.5vw;
  }

  .phone-number-buy-confirmation-controls > button {
    margin: 20px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */

}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */

}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */

}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */

}