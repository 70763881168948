.owned-number {
  cursor: default !important;
}

.owned-number > a {
  text-decoration: none;
}

.owned-number-list-item-meta-control {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.owned-number-list-item-meta-control > .owned-number-list-item-header-wrapper {
  text-align: left;
  padding: 10px;
  align-self: center;

}

.owned-number-list-item-header-wrapper > p {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: black;
}

.owned-number-list-item-meta-control > .owned-number-list-item-controls {
  text-align: right;
  padding: 10px;
}