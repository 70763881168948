.button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 150px;
  border: 1px solid white;
  border-radius: 25px;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  color: #F4FAFF;
  text-align: center;
  padding: 0 20px;
  cursor: pointer;
  transition: all .1s ease-in-out;
}
@media screen and (max-width: 500px) {
  .button {
    height: 40px;
    min-width: 80px;
    font-size: 14px;
  }
}


.button[disabled] {
  opacity: .4
}
.button[disabled]:hover {
  background-color: gray; 
  cursor: no-drop;
}

/* Button spinner */
.button > .button-title {
  display: block;
}
.button > .button-spinner {
  display: none;
}
.button.loading {
  pointer-events: none;
}
.button.loading:hover {
  background: #C09BD8;
}
.button.loading > .button-title {
  display: none;
}
.button.loading > .button-spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: red;
}