.fullsize-navbar-wrapper {
  display: grid;
  grid-template-columns: 10% 90%;
  overflow: hidden;
  z-index: 5;
  position: fixed; 
  top: 0; 
  width: 100%; 
  background-color: white;
}

.fullsize-navbar-wrapper > .fullsize-navbar-brand-wrapper{
  text-align: left;
}

.fullsize-navbar-links-wrapper {
  align-self: center;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .fullsize-navbar-wrapper {
    padding: 10px 10px 0 10px;
    max-height: 200px;
  }

  .fullsize-navbar-brand-wrapper {
    padding-bottom: 8px;
  }

  .fullsize-navbar-brand-image {
    height: auto;
    width: 5vw;
    max-width: 80px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */

  .fullsize-navbar-wrapper {
    padding: 10px 10px 0 10px;
  }

  .fullsize-navbar-brand-wrapper {
    padding-bottom: 8px;
  }

  .fullsize-navbar-brand-image {
    height: auto;
    width: 10vw;
    max-width: 65px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  
  .fullsize-navbar-wrapper {
    padding: 10px 10px 0 10px;
  }

  .fullsize-navbar-brand-wrapper {
    padding-bottom: 8px;
  }

  .fullsize-navbar-brand-image {
    height: auto;
    width: 12vw;
  }
}

