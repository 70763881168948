.login-container-wrapper {
  background-color: #8338EC;
  height: 100vh;
}

.login-container-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.login-container-subheading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

.login-container-heading-wrapper {
  text-align: center;
}

.login-container-oauth-wrapper {
  text-align: center;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .login-container-heading-wrapper {
    padding-top: 50px;
  }

  .login-container-heading {
    margin-bottom: 5px;
    font-size: 2vw;
  }

  .login-container-subheading {
    font-size: 1.5vw;
  }

  .login-container-oauth-wrapper {
    margin-top: 50px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .login-container-heading-wrapper {
    padding-top: 50px;
  }

  .login-container-heading {
    margin-bottom: 5px;
    font-size: 4vw;
  }

  .login-container-subheading {
    font-size: 2vw;
  }

  .login-container-oauth-wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .login-container-heading-wrapper {
    padding-top: 50px;
  }

  .login-container-heading {
    margin-bottom: 5px;
    font-size: 4vw;
  }

  .login-container-subheading {
    font-size: 2vw;
  }

  .login-container-oauth-wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .login-container-heading-wrapper {
    padding-top: 50px;
  }

  .login-container-heading {
    margin-bottom: 5px;
    font-size: 7vw;
  }

  .login-container-subheading {
    font-size: 4vw;
  }

  .login-container-oauth-wrapper {
    margin-top: 50px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .login-container-heading-wrapper {
    padding-top: 25px;
  }

  .login-container-heading {
    margin-bottom: 5px;
    font-size: 7vw;
  }

  .login-container-subheading {
    font-size: 4vw;
  }

  .login-container-oauth-wrapper {
    margin-top: 50px;
  }
}