.mobile-navbar-overlay-wrapper {
  /* styles for modal */
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: black;
  /* styles for modal end*/
}

.mobile-navbar-overlay-header-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}

.mobile-navbar-overlay-header-wrapper > .mobile-navbar-overlay-brand-wrapper{
  text-align: left;
}

.mobile-navbar-overlay-close-wrapper {
  text-align: right;
  align-self: center;
}

.mobile-navbar-overlay-close {
  filter: invert(1);
}


@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .mobile-navbar-overlay-wrapper {
    padding: 5px 0 5px 0;
  }

  .mobile-navbar-overlay-content-wrapper {
    padding: 0 10px 0 10px;
  }

  .mobile-navbar-overlay-close {
    width: auto;
    height: 35px;
  }

  .mobile-navbar-overlay-brand-logo {
    width: auto;
    height: 45px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .mobile-navbar-overlay-wrapper {
    padding: 5px 0 5px 0;
  }

  .mobile-navbar-overlay-content-wrapper {
    padding: 0 10px 0 10px;
  }

  .mobile-navbar-overlay-close {
    width: auto;
    height: 30px;
  }

  .mobile-navbar-overlay-brand-logo {
    width: auto;
    height: 40px;
  }
}