.next-button {
  font-family: 'Raleway', sans-serif;
  color: black !important;
  font-weight: 400;
  background-color: #FFBE0B;
  display: inline-block !important;
}




/* remove default margin from body */
body {
  margin: 0;
}

/* remove default margin from headings and paragraphs */
h1, h2, p {
  margin: 0;
}