.footer-wrapper {
  background-color: #FFBE0B;
}

.footer-wrapper-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footer-bottom-wrapper {
  text-align: center;
}

.footer-column-heading-wrapper > h1 {
  font-family: 'Raleway', sans-serif;
  color: black;
  font-weight: 600;
}

.footer-item-wrapper > a {
  display: block;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  color: black;
  font-weight: 400;
}

.social-media-wrapper {
  display: flex !important;
}

.footer-bottom-wrapper > div {
  border-top: 1px solid black;
  height: 10px;
}

.footer-bottom-wrapper > p {
  font-family: 'Raleway', sans-serif;
  color: black;
  font-weight: 600;
}

.footer-bottom-wrapper a {
  font-family: 'Raleway', sans-serif;
  color: black;
  text-decoration: none;
  font-weight: 700;
}

@media (min-width: 1025px) {
  /* desktop styles */

  .footer-wrapper {
    height: 30vh;
  }

  .footer-wrapper {
    padding: 50px 50px;
  }

  .footer-column-wrapper {
    padding: 25px;
  }

  .footer-item-wrapper {
    margin: 20px 0;
  }

  .footer-item-wrapper > a {
    margin: 5px 0;
  }

  .social-media-wrapper > a > img{
    height: auto;
    width: 40px;
  }

  .footer-bottom-wrapper > div {
    margin: 0 20vw;
  }

  .footer-column-heading-wrapper > h1 {
    font-size: 30px;
    margin: 5px 0;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */

  .footer-wrapper {
    height: 30vh;
  }

  .footer-wrapper {
    padding: 50px 25px;
  }

  .footer-column-wrapper {
    padding: 25px;
  }

  .footer-item-wrapper {
    margin: 20px 0;
  }

  .footer-item-wrapper > a {
    margin: 5px 0;
  }

  .social-media-wrapper > a > img{
    height: auto;
    width: 40px;
  }

  .footer-bottom-wrapper > div {
    margin: 0 20vw;
  }

  .footer-column-heading-wrapper > h1 {
    font-size: 30px;
    margin: 5px 0;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .footer-wrapper {
    height: 30vh;
  }

  .footer-wrapper {
    padding: 50px 25px;
  }

  .footer-column-wrapper {
    padding: 20px;
  }

  .footer-item-wrapper {
    margin: 20px 0;
  }

  .footer-item-wrapper > a {
    margin: 5px 0;
  }

  .social-media-wrapper > a > img{
    height: auto;
    width: 40px;
  }

  .footer-bottom-wrapper > div {
    margin: 0 20vw;
  }

  .footer-column-heading-wrapper > h1 {
    font-size: 30px;
    margin: 5px 0;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .footer-wrapper {
    height: 70vh;
  }

  .footer-wrapper {
    padding: 25px 10px;
  }

  .footer-column-wrapper {
    padding: 10px;
  }

  .footer-item-wrapper {
    margin: 20px 0;
  }

  .footer-item-wrapper > a {
    margin: 5px 0;
  }

  .social-media-wrapper > a > img{
    height: auto;
    width: 40px;
  }

  .footer-bottom-wrapper > div {
    margin: 0 20vw;
  }

  .footer-column-heading-wrapper > h1 {
    font-size: 20px;
    margin: 5px 0;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .footer-wrapper {
    height: 70vh;
  }

  .footer-wrapper {
    padding: 25px 5px;
  }

  .footer-column-wrapper {
    padding: 10px;
  }

  .footer-item-wrapper {
    margin: 20px 0;
  }

  .footer-item-wrapper > a {
    margin: 5px 0;
  }

  .social-media-wrapper > a > img{
    height: auto;
    width: 40px;
  }

  .footer-bottom-wrapper > div {
    margin: 0 20vw;
  }

  .footer-column-heading-wrapper > h1 {
    font-size: 20px;
    margin: 5px 0;
  }
}