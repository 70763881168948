.phone-number-buy-success-wrapper {
  text-align: center;
}

.phone-number-buy-success-info {
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.phone-number-buy-success-controls .btn-anchor {
  display: inline-block;
}


@media (min-width: 1025px) {
  /* desktop styles */
  .phone-number-buy-success-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-success-info {
    font-size: 1.5vw;
    margin: 20px;
  }

  .phone-number-buy-success-controls {
    padding-top: 50px;
  }

  .phone-number-buy-success-controls .btn-anchor {
    margin: 20px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .phone-number-buy-success-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-success-info {
    font-size: 2vw;
    margin: 20px;
  }

  .phone-number-buy-success-controls {
    padding-top: 50px;
  }

  .phone-number-buy-success-controls .btn-anchor {
    margin: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .phone-number-buy-success-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-success-info {
    font-size: 2vw;
    margin: 20px;
  }

  .phone-number-buy-success-controls {
    padding-top: 50px;
  }

  .phone-number-buy-success-controls .btn-anchor {
    margin: 20px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .phone-number-buy-success-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-success-info {
    font-size: 4vw;
    margin: 20px;
  }

  .phone-number-buy-success-controls {
    padding-top: 50px;
  }

  .phone-number-buy-success-controls .btn-anchor {
    margin: 20px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .phone-number-buy-success-wrapper {
    padding-top: 50px;
  }

  .phone-number-buy-success-info {
    font-size: 4vw;
    margin: 20px;
  }

  .phone-number-buy-success-controls {
    padding-top: 50px;
  }

  .phone-number-buy-success-controls .btn-anchor {
    margin: 20px;
  }
}