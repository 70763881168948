.phone-number-list-item-wrapper {
  background: #FFBE0B;  
  border-radius: 38px;
  display: inline-block;
  cursor: pointer;
}

.phone-number-list-item-header-monthly {
  text-align: right;
  font-family: 'Raleway', sans-serif;
  color: black;
  font-weight: 400;
}

.phone-number-list-item-number {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: black;
  font-weight: 600;
}

.phone-number-list-item-footer-permsg {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: black;
  font-weight: 400;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .phone-number-list-item-wrapper {
    margin: 20px;
    width: 30vw;
  }

  .phone-number-list-item-header-monthly, .phone-number-list-item-footer-permsg {
    margin: 25px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .phone-number-list-item-wrapper {
    margin: 30px;
    width: 40vw;
  }

  .phone-number-list-item-header-monthly, .phone-number-list-item-footer-permsg {
    margin: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .phone-number-list-item-wrapper {
    margin: 30px;
    width: 40vw;
  }

  .phone-number-list-item-header-monthly, .phone-number-list-item-footer-permsg {
    margin: 20px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .phone-number-list-item-wrapper {
    margin: 20px;
    width: 75vw;
  }

  .phone-number-list-item-header-monthly, .phone-number-list-item-footer-permsg {
    margin: 20px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .phone-number-list-item-wrapper {
    margin: 20px;
    width: 80vw;
  }

  .phone-number-list-item-header-monthly, .phone-number-list-item-footer-permsg {
    margin: 20px;
  }
}