.appstores-group {
  text-align: center;
}

.appstores-group img {
  height: auto;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .appstores-group {
    margin: 40px 0;
  }

  .appstores-group img {
    width: 8vw;
    margin: 20px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .appstores-group {
    margin: 40px 0;
  }

  .appstores-group img {
    width: 15vw;
    margin: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .appstores-group {
    margin: 40px 0;
  }

  .appstores-group img {
    width: 15vw;
    margin: 20px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .appstores-group {
    margin: 20px 0;
  }

  .appstores-group img {
    width: 30vw;
    margin: 20px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .appstores-group {
    margin: 20px 0;
  }

  .appstores-group img {
    width: 30vw;
    margin: 20px;
  }
}