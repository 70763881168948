body.no-scroll {
  overflow: hidden;
}

.App-landscape {
  margin-top: 85px;
}


@media (min-width: 1025px) {
  /* desktop styles */
  .App-desktop {
    margin-top: 90px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .App-tablet {
    margin-top: 80px;
  }

  .App-desktop {
    margin-top: 74px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .App-tablet {
    margin-top: 85px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .App-mobile {
    /* to clear fixed navbar */
    margin-top: 56px;
  } 
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .App-mobile {
    /* to clear fixed navbar */
    margin-top: 51px;
  } 
}