.load-account-wrapper {
  height: 100%;
  min-height: 100vh;
  background-color: #8338EC;
}

.load-account-heading-wrapper {
  text-align: center;
}

.load-account-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.load-account-controls-wrapper {
  text-align: center;
}



@media (min-width: 1025px) {
  /* desktop styles */
  .load-account-heading-wrapper {
    padding-top: 50px;
  }

  .load-account-heading {
    font-size: 2vw;
  }

  .load-account-dropdown-wrapper {
    padding: 50px;
    margin: 0.5vh 10vw;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .load-account-heading-wrapper {
    padding-top: 50px;
  }

  .load-account-heading {
    font-size: 4vw;
  }

  .load-account-dropdown-wrapper {
    padding: 50px;
    margin: 0.5vh 10vw;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .load-account-heading-wrapper {
    padding-top: 50px;
  }

  .load-account-heading {
    font-size: 4vw;
  }

  .load-account-dropdown-wrapper {
    padding: 50px;
    margin: 0.5vh 10vw;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .load-account-heading-wrapper {
    padding-top: 30px;
  }

  .load-account-heading {
    font-size: 7vw;
  }

  .load-account-dropdown-wrapper {
    padding: 30px;
    margin: 0.5vh 6vw;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .load-account-heading-wrapper {
    padding-top: 30px;
  }

  .load-account-heading {
    font-size: 7vw;
  }

  .load-account-dropdown-wrapper {
    padding: 30px;
    margin: 0.5vh 4vw;
  }
}