.new-conversation-border {
  border: 1px solid black;
}

.new-conversation-wrapper {
  text-align: center;
  border-radius: 38px;
}

.new-conversation-message-input-wrapper > div {
  text-align: center;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .new-conversation-wrapper {
    margin: 20px 20px;
  }

  .new-conversation-phone-input {
    margin: 50px 20%;
  }

  .new-conversation-phone-input .PhoneInputInput {
    padding: 10px;
    font-size: 18px;
  }

  .new-conversation-phone-input .PhoneInputCountryIcon {
    height: auto;
    width: 50px;
  }

  .new-conversation-message-input-wrapper > div {
    margin: 10px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .new-conversation-wrapper {
    margin: 20px 20px;
  }

  .new-conversation-phone-input {
    margin: 50px 20%;
  }

  .new-conversation-phone-input .PhoneInputInput {
    padding: 10px;
    font-size: 18px;
  }

  .new-conversation-phone-input .PhoneInputCountryIcon {
    height: auto;
    width: 50px;
  }

  .new-conversation-message-input-wrapper > div {
    margin: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .new-conversation-wrapper {
    margin: 20px 20px;
  }

  .new-conversation-phone-input {
    margin: 50px 20%;
  }

  .new-conversation-phone-input .PhoneInputInput {
    padding: 10px;
    font-size: 18px;
  }

  .new-conversation-phone-input .PhoneInputCountryIcon {
    height: auto;
    width: 50px;
  }

  .new-conversation-message-input-wrapper > div {
    margin: 10px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .new-conversation-wrapper {
    margin: 20px 20px;
  }

  .new-conversation-phone-input {
    margin: 50px 5%;
  }

  .new-conversation-phone-input .PhoneInputInput {
    padding: 10px;
    font-size: 18px;
  }

  .new-conversation-phone-input .PhoneInputCountryIcon {
    height: auto;
    width: 50px;
  }

  .new-conversation-message-input-wrapper > div {
    margin: 10px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .new-conversation-wrapper {
    margin: 20px 20px;
  }

  .new-conversation-phone-input {
    margin: 25px 2%;
  }

  .new-conversation-phone-input .PhoneInputInput {
    padding: 10px;
    font-size: 18px;
  }

  .new-conversation-phone-input .PhoneInputCountryIcon {
    height: auto;
    width: 50px;
  }

  .new-conversation-message-input-wrapper > div {
    margin: 10px;
  }
}