.explore-pricing-wrapper {
  background-color: #FFBE0B;
  height: 100vh;
  position: relative;
  text-align: center;
  position: relative;
}

.explore-pricing-header-wrapper {
  text-align: left;
}

.explore-pricing-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.explore-pricing-subheading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .explore-pricing-wrapper {
    padding: 30px 10px;
  }

  .explore-pricing-header-wrapper {
    margin: 0 20vw;
  }

  .explore-pricing-heading {
    font-size: 2vw;
    margin: 0 0 15px 0;
  }
  
  .explore-pricing-subheading {
    font-size: 1.5vw;
    margin: 0 0 40px 0;
  }

  .explore-pricing-money {
    height: auto;
    width: 8vw;
    top: 3%;
    left: 75%;
    position: absolute;
  }

  .explore-pricing-calculator {
    height: auto;
    width: 45vw;
    margin-top: 80px;
  }

}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .explore-pricing-wrapper {
    padding: 30px 10px;
  }

  .explore-pricing-header-wrapper {
    margin: 0 20vw;
  }

  .explore-pricing-heading {
    font-size: 4vw;
    margin: 0 0 15px 0;
  }
  
  .explore-pricing-subheading {
    font-size: 2vw;
    margin: 0 0 15px 0;
  }

  .explore-pricing-money {
    height: auto;
    width: 8vw;
    top: 3%;
    left: 75%;
    position: absolute;
  }

  .explore-pricing-calculator {
    height: auto;
    width: 60vw;
    margin-top: 80px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .explore-pricing-wrapper {
    padding: 30px 10px;
  }

  .explore-pricing-header-wrapper {
    margin: 0 20vw;
  }

  .explore-pricing-heading {
    font-size: 4vw;
    margin: 0 0 15px 0;
  }
  
  .explore-pricing-subheading {
    font-size: 2vw;
    margin: 0 0 15px 0;
  }

  .explore-pricing-money {
    height: auto;
    width: 8vw;
    top: 3%;
    left: 75%;
    position: absolute;
  }

  .explore-pricing-calculator {
    height: auto;
    width: 60vw;
    margin-top: 80px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .explore-pricing-wrapper {
    padding: 30px 10px;
  }

  .explore-pricing-header-wrapper {
    margin: 0 20vw;
  }

  .explore-pricing-heading {
    font-size: 4vw;
    line-height: 22px;
    margin: 0 0 15px 0;
  }
  
  .explore-pricing-subheading {
    font-size: 3vw;
    line-height: 20px;
    margin: 0 0 15px 0;
  }

  .explore-pricing-money {
    height: auto;
    width: 8vw;
    top: 5%;
    left: 67%;
    position: absolute;
  }

  .explore-pricing-calculator {
    height: auto;
    width: 80vw;
    margin-top: 40px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .explore-pricing-wrapper {
    padding: 30px 10px;
  }

  .explore-pricing-header-wrapper {
    margin: 0 20vw;
  }

  .explore-pricing-heading {
    font-size: 4vw;
    line-height: 22px;
    margin: 0 0 15px 0;
  }
  
  .explore-pricing-subheading {
    font-size: 3vw;
    line-height: 20px;
    margin: 0 0 15px 0;
  }

  .explore-pricing-money {
    height: auto;
    width: 8vw;
    top: 5%;
    left: 67%;
    position: absolute;
  }

  .explore-pricing-calculator {
    height: auto;
    width: 80vw;
    margin-top: 40px;
  }
}