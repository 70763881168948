.account-wrapper {
  background-color: #8338EC;
  height: 100%;
  min-height: 100vh;
}

.account-actions-wrapper {
  text-align: center;
}

.account-actions-wrapper > .button {
  margin: 20px;
}