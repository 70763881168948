.cost-estimation-wrapper {
  background-color: #8338EC;
  height: 100vh;
}

.cost-estimation-heading-wrapper {
  text-align: center;
}

.cost-estimation-heading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 600;
}

.cost-estimation-subheading {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 400;
}

.cost-estimation-button-wrapper {
  text-align: center;
}

.cost-estimation-country-wrapper {
  margin: 0 auto;
}

@media (min-width: 1025px) {
  /* desktop styles */
  .cost-estimation-heading-wrapper {
    padding-top: 50px;
  }

  .cost-estimation-heading {
    font-size: 2vw;
  }
  
  .cost-estimation-subheading {
    font-size: 1.5vw;
  }

  .cost-estimation-country-wrapper {
    margin: 100px 20vw;
  }

  .cost-estimation-button-wrapper {
    margin-top: 20px;
  }
}


@media (min-width: 700px) and (max-width: 1025px) {
  /* small tablet styles */
  .cost-estimation-heading-wrapper {
    padding-top: 50px;
  }

  .cost-estimation-heading {
    font-size: 4vw;
  }
  
  .cost-estimation-subheading {
    font-size: 2vw;
  }

  .cost-estimation-country-wrapper {
    margin-top: 80px;
    width: 80%;
  }

  .cost-estimation-button-wrapper {
    margin-top: 80px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  /* large tablet styles */
  .cost-estimation-heading-wrapper {
    padding-top: 50px;
  }

  .cost-estimation-heading {
    font-size: 4vw;
  }
  
  .cost-estimation-subheading {
    font-size: 2vw;
  }

  .cost-estimation-country-wrapper {
    margin-top: 80px;
    width: 80%;
  }

  .cost-estimation-button-wrapper {
    margin-top: 80px;
  }
}

@media (min-width: 374px) and (max-width: 700px) {
  /* large mobile styles */
  .cost-estimation-wrapper {
    height: 200vh;
  }

  .cost-estimation-heading-wrapper {
    padding-top: 25px;
  }

  .cost-estimation-heading {
    font-size: 7vw;
  }
  
  .cost-estimation-subheading {
    font-size: 4vw;
  }

  .cost-estimation-country-wrapper {
    margin-top: 30px;
    width: 90%;
  }

  .cost-estimation-button-wrapper {
    margin-top: 40px;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  /* small mobile styles */
  .cost-estimation-wrapper {
    height: 200vh;
  }

  .cost-estimation-heading-wrapper {
    padding-top: 25px;
  }

  .cost-estimation-heading {
    font-size: 7vw;
  }
  
  .cost-estimation-subheading {
    font-size: 4vw;
  }

  .cost-estimation-country-wrapper {
    margin-top: 20px;
    width: 90%;
  }

  .cost-estimation-button-wrapper {
    margin-top: 40px;
  }
}